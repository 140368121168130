/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
.rdp {
  --rdp-cell-size: 40px;
  --rdp-caption-font-size: 18px;
  --rdp-accent-color: #0000ff;
  --rdp-background-color: #e7edff;
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
  margin: 1em;
}

/* Hide elements for devices that are not screen readers */
.rdp-vhidden {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: absolute !important;
  top: 0;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
}

/* Buttons */
.rdp-button_reset {
  appearance: none;
  position: relative;
  margin: 0;
  padding: 0;
  cursor: default;
  color: inherit;
  background: none;
  font: inherit;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.rdp-button_reset:focus-visible {
  /* Make sure to reset outline only when :focus-visible is supported */
  outline: none;
}

.rdp-button {
  border: 2px solid transparent;
}

.rdp-button[disabled]:not(.rdp-day_selected) {
  opacity: 0.25;
}

.rdp-button:not([disabled]) {
  cursor: pointer;
}

.rdp-button:focus-visible:not([disabled]) {
  color: inherit;
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: var(--rdp-background-color);
}

.rdp-months {
  display: flex;
}

.rdp-month {
  margin: 0 1em;
}

.rdp-month:first-child {
  margin-left: 0;
}

.rdp-month:last-child {
  margin-right: 0;
}

.rdp-table {
  margin: 0;
  max-width: calc(var(--rdp-cell-size) * 7);
  border-collapse: collapse;
}

.rdp-with_weeknumber .rdp-table {
  max-width: calc(var(--rdp-cell-size) * 8);
  border-collapse: collapse;
}

.rdp-caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  text-align: left;
}

.rdp-multiple_months .rdp-caption {
  position: relative;
  display: block;
  text-align: center;
}

.rdp-caption_dropdowns {
  position: relative;
  display: inline-flex;
}

.rdp-caption_label {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0 0.25em;
  white-space: nowrap;
  color: currentColor;
  border: 0;
  border: 2px solid transparent;
  font-family: inherit;
  font-size: var(--rdp-caption-font-size);
  font-weight: bold;
}

.rdp-nav {
  white-space: nowrap;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.rdp-multiple_months .rdp-caption_end .rdp-nav {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.rdp-nav_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  padding: 0.25em;
  border-radius: 100%;
}

/* ---------- */
/* Dropdowns  */
/* ---------- */
.rdp-dropdown_year,
.rdp-dropdown_month {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.rdp-dropdown {
  appearance: none;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: inherit;
  opacity: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.rdp-dropdown[disabled] {
  opacity: unset;
  color: unset;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
  border-radius: 6px;
}

.rdp-dropdown_icon {
  margin: 0 0 0 5px;
}

.rdp-head {
  border: 0;
}

.rdp-head_row,
.rdp-row {
  height: 100%;
}

.rdp-head_cell {
  vertical-align: middle;
  font-size: 0.75em;
  font-weight: 700;
  text-align: center;
  height: 100%;
  height: var(--rdp-cell-size);
  padding: 0;
  text-transform: uppercase;
}

.rdp-tbody {
  border: 0;
}

.rdp-tfoot {
  margin: 0.5em;
}

.rdp-cell {
  width: var(--rdp-cell-size);
  height: 100%;
  height: var(--rdp-cell-size);
  padding: 0;
  text-align: center;
}

.rdp-weeknumber {
  font-size: 0.75em;
}

.rdp-weeknumber,
.rdp-day {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: var(--rdp-cell-size);
  max-width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  margin: 0;
  border: 2px solid transparent;
  border-radius: 100%;
}

.rdp-day_today:not(.rdp-day_outside) {
  font-weight: bold;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  color: white;
  opacity: 1;
  background-color: var(--rdp-accent-color);
}

.rdp-day_outside {
  opacity: 0.5;
}

.rdp-day_selected:focus-visible {
  /* Since the background is the same use again the outline */
  outline: var(--rdp-outline);
  outline-offset: 2px;
  z-index: 1;
}

.rdp:not([dir=rtl]) .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp:not([dir=rtl]) .rdp-day_range_end:not(.rdp-day_range_start) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rdp[dir=rtl] .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rdp[dir=rtl] .rdp-day_range_end:not(.rdp-day_range_start) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp-day_range_end.rdp-day_range_start {
  border-radius: 100%;
}

.rdp-day_range_middle {
  border-radius: 0;
}


.bp5-datepicker-content .rdp {
  --rdp-cell-size: 30px;
  --rdp-accent-color: #2d72d2;
  --rdp-background-color: #ffffff;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #215db0;
  --rdp-background-color-dark: #2f343c;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 75%);
  margin: 0;
  min-width: auto;
}

/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable selector-class-pattern */
.bp5-datepicker .rdp {
  display: inline-block;
  min-width: 210px;
  position: relative;
  vertical-align: top;
}
.bp5-datepicker .rdp:focus {
  outline: none;
}
.bp5-datepicker .rdp-month {
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  user-select: none;
}
.bp5-datepicker .rdp-month + .bp5-datepicker .rdp-month {
  margin-left: 10px;
}
.bp5-datepicker .rdp-caption {
  border-bottom: solid 1px rgba(17, 20, 24, 0.15);
  padding-bottom: 5px;
}
.bp5-datepicker .rdp-table {
  align-self: center;
}
.bp5-datepicker .rdp-head_cell {
  font-size: inherit;
  font-weight: 600;
  padding-top: 5px;
  text-decoration: none;
  text-transform: none;
}
.bp5-datepicker .rdp-weeknumber {
  color: #5f6b7c;
}
.bp5-datepicker .rdp-day {
  border-radius: 2px;
}
.bp5-datepicker .rdp-day.rdp-day_outside {
  color: rgba(95, 107, 124, 0.6);
}
.bp5-datepicker .rdp-day.rdp-day_today {
  font-weight: 400;
}
.bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):hover, .bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):focus {
  background: rgba(143, 153, 168, 0.15);
  color: #1c2127;
}
.bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):active {
  background: rgba(143, 153, 168, 0.3);
}
.bp5-datepicker .rdp-day.rdp-day_disabled {
  background: none;
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
}
.bp5-datepicker .rdp-day.rdp-day_selected {
  background-color: #2d72d2;
  border-radius: 2px;
  color: #ffffff;
}
.bp5-datepicker .rdp-day.rdp-day_selected:hover {
  background-color: #215db0;
  color: #ffffff;
}
.bp5-datepicker .rdp-day.rdp-day_selected:active {
  background-color: #184a90;
}
.bp5-datepicker.bp5-datepicker-highlight-current-day .rdp-day.rdp-day_today {
  border: 1px solid rgba(17, 20, 24, 0.15);
}
.bp5-datepicker.bp5-datepicker-reverse-month-and-year .rdp-caption_dropdowns {
  flex-direction: row-reverse;
}

.bp5-datepicker-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.bp5-datepicker-content > .bp5-divider {
  margin: 0;
  width: calc(100% - 10px);
}

.bp5-datepicker-month-select select, .bp5-datepicker-year-select select {
  font-weight: 600;
  padding-left: 5px;
  padding-right: 16px;
}
.bp5-datepicker-month-select select + .bp5-icon, .bp5-datepicker-year-select select + .bp5-icon {
  right: 2px;
}

.bp5-datepicker-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.bp5-dark .bp5-datepicker {
  background: #2f343c;
}
.bp5-dark .bp5-datepicker .rdp-week-number {
  color: #abb3bf;
}
.bp5-dark .bp5-datepicker .rdp-day.rdp-day_outside {
  color: rgba(171, 179, 191, 0.6);
}
.bp5-dark .bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):hover, .bp5-dark .bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):focus {
  background: rgba(143, 153, 168, 0.15);
  color: #ffffff;
}
.bp5-dark .bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):active {
  background: rgba(143, 153, 168, 0.3);
}
.bp5-dark .bp5-datepicker .rdp-day.rdp-day_selected {
  background-color: #2d72d2;
}
.bp5-dark .bp5-datepicker .rdp-day.rdp-day_selected:hover {
  background-color: #215db0;
}
.bp5-dark .bp5-datepicker .rdp-day.rdp-day_selected:active {
  background-color: #184a90;
}
.bp5-dark .bp5-datepicker .rdp-day.rdp-day_disabled {
  background: none;
  color: rgba(171, 179, 191, 0.6);
}
.bp5-dark .bp5-datepicker.bp5-datepicker-highlight-current-day .rdp-day.rdp-day_today {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.bp5-dark .bp5-datepicker .bp5-datepicker-footer {
  border-top-color: rgba(17, 20, 24, 0.4);
}

.bp5-datepicker-timepicker-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.bp5-datepicker-timepicker-wrapper .bp5-timepicker-arrow-row:empty + .bp5-timepicker-input-row {
  margin: 5px 0;
}

/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable selector-class-pattern */
.bp5-datepicker-caption.rdp-caption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
}
.bp5-datepicker-caption.rdp-caption > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.bp5-datepicker-caption.rdp-caption > .bp5-fill {
  flex-grow: 1;
  flex-shrink: 1;
}
.bp5-datepicker-caption.rdp-caption .bp5-html-select select {
  font-weight: 600;
  padding-left: 5px;
  padding-right: 16px;
}
.bp5-datepicker-caption.rdp-caption .bp5-html-select select + .bp5-icon {
  right: 2px;
}
.bp5-datepicker-caption.rdp-caption + .bp5-divider {
  margin: 0;
}
.bp5-datepicker-caption.rdp-caption .bp5-datepicker-nav-button-hidden {
  visibility: hidden;
}

.bp5-datepicker-month-select {
  flex-shrink: 1;
}

.bp5-datepicker-year-select {
  flex-shrink: 1;
  min-width: 60px;
}

.bp5-datepicker-caption-measure {
  font-weight: 600;
  padding-left: 5px;
}

/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable selector-class-pattern */
.bp5-daterangepicker {
  /* stylelint-disable max-line-length */
  /* stylelint-enable max-line-length */
}
.bp5-daterangepicker.bp5-daterangepicker-contiguous .rdp {
  min-width: 220px;
}
.bp5-daterangepicker.bp5-daterangepicker-single-month .rdp {
  min-width: 210px;
}
.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption > .bp5-fill {
  flex-grow: 1;
  flex-shrink: 1;
}
.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption_start .rdp-caption {
  flex-direction: row-reverse;
}
.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption_start .rdp-caption::before {
  content: "";
  height: 30px;
  width: 30px;
}
.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption_end .rdp-caption {
  flex-direction: row;
}
.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption_end .rdp-caption::before {
  content: "";
  height: 30px;
  width: 30px;
}
.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-nav {
  left: initial;
  position: initial;
  top: initial;
  transform: none;
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_outside {
  visibility: hidden;
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_hovered:not(.rdp-day_selected) {
  border-radius: 0;
  color: #215db0;
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_hovered:not(.rdp-day_selected):not(.rdp-day_range_start):not(.rdp-day_range_middle):not(.rdp-day_range_end) {
  background-color: rgba(45, 114, 210, 0.1);
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_range_middle {
  background-color: rgba(45, 114, 210, 0.1);
  border-radius: 0;
  color: #215db0;
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_range_middle:hover {
  background-color: rgba(45, 114, 210, 0.2);
  color: #215db0;
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_range_start:not(.rdp-day_range_end):not(.rdp-day_hovered_end) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_range_end:not(.rdp-day_range_start):not(.rdp_day_hovered_start) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_hovered_start:not(.rdp-day_hovered_end) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_hovered_end:not(.rdp-day_hovered_start) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.bp5-daterangepicker.bp5-datepicker-highlight-current-day .rdp-day.rdp-day_today {
  border: 1px solid rgba(17, 20, 24, 0.15);
}
.bp5-daterangepicker.bp5-daterangepicker-reverse-month-and-year.bp5-daterangepicker-contiguous .rdp-caption_dropdowns {
  flex-direction: row-reverse;
}

.bp5-daterangepicker-timepickers.bp5-daterangepicker-timepickers-stacked {
  align-items: center;
  flex-direction: column;
}

.bp5-dark .bp5-daterangepicker.bp5-datepicker .rdp-day_hovered {
  color: #f6f7f9;
  /* stylelint-disable max-line-length */
  /* stylelint-enable max-line-length */
}
.bp5-dark .bp5-daterangepicker.bp5-datepicker .rdp-day_hovered:not(.rdp-day_selected):not(.rdp-day_range_start):not(.rdp-day_range_middle):not(.rdp-day_range_end) {
  background-color: rgba(45, 114, 210, 0.2);
}
.bp5-dark .bp5-daterangepicker.bp5-datepicker .rdp-day_range_middle {
  background-color: rgba(45, 114, 210, 0.2);
  color: #f6f7f9;
}
.bp5-dark .bp5-daterangepicker.bp5-datepicker .rdp-day_range_middle:hover {
  background-color: rgba(45, 114, 210, 0.4);
}
.bp5-dark .bp5-daterangepicker.bp5-datepicker-highlight-current-day .rdp-day.rdp-day_today {
  border: 1px solid rgba(255, 255, 255, 0.2);
}